var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.disabled,
          expression: "!disabled"
        }
      ],
      staticClass: "btn btn-primary",
      attrs: { id: "next", type: "button" },
      on: { click: _vm.next }
    },
    [_vm._v("3. " + _vm._s(_vm.trans("buttons.next")))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }