$(document).ready(function() {
	var dataTarget = '';
	var comment = '';

	/**
	 * copy comment team->customer customer->team
	 */
	$('a[data-target].copyComment').click(function(e){
		e.preventDefault();
		_this = $(this);
		dataTarget = _this.data('target');
		comment = _this.prevAll('span').text();
		$('textarea[id="' + dataTarget + '"]').first().val(comment);
	});

	$('a[data-target].copyCommentTeam').click(function(e){
		e.preventDefault();
		_this = $(this);
		dataTarget = _this.data('target');
		
		var element = document.getElementById(_this.data('id'));
		var hasClass = element.classList.contains('active');

		comment = document.getElementById('span_'+ _this.data('id')).innerHTML;
		
		

		if(hasClass){
			element.classList.remove("active");
			element.blur();
			$('textarea[id="' + dataTarget + '"]').first().val('');
		}else{
			$('textarea[id="' + dataTarget + '"]').first().val(comment);
			element.classList.add("active");
		}		
		// comment = _this.prevAll('span').text();
		// comment = comment.replace(","," ");
		
		// if(hasClass){
		// 	element.classList.remove("active");
		// 	element.blur();
		// 	commentArray = commentArray.filter(item => item !== comment + '\n')
		// }else {
		// 	element.classList.add("active");
		// 	oldComment = $('textarea[id="' + dataTarget + '"]').first().val();
		// 	// if(oldComment){
		// 	// 	commentArray.push(comment);
		// 	// }else {
		// 	// 	commentArray = [];
		// 	// 	commentArray.push(comment);
		// 	// }
		// 	if(oldComment){
		// 		commentArray.push(comment + '\n');
		// 	}else {
		// 		commentArray = [];
		// 		commentArray.push(comment + '\n');
		// 	}

		//}
		
		//$('textarea[id="' + dataTarget + '"]').first().val(commentArray);
		//$('textarea[id="' + dataTarget + '"]').first().text(comment);
	});

	/**
	 * Mark comment as read/unread (switch fa-envelope)
	 */
	$('.js-comments tbody').on('click', '.js-toggle-read', function (e) {
		e.preventDefault();

		var $this = $(this);
		var $form = $this.closest('form');
		var $label = $this.children('span.status');
		var $icon = $this.find('svg');
		$.ajax({
			type: "POST",
			url: $form.attr('action'),
			data: $form.serialize(),
			success: function (response) {
				$this.removeClass('read unread');
				if (response.read) {
					$this.addClass('read');
					$label.text('1');
					$icon.attr('data-icon', 'envelope-open')
				} else {
					$this.addClass('unread');
					$label.text('0');
					$icon.attr('data-icon', 'envelope')
				}
			}
		});
	});
});