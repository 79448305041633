/*
 * Sort abbreviated file sizes correctly
 * @url https://datatables.net/plug-ins/sorting/file-size
 */
jQuery.fn.dataTable.ext.type.order['file-size-pre'] = function (data) {
	var matches = data.match(/^(\d+(?:\.\d+)?)\s*([a-z]+)/i);
	var multipliers = {
		b: 1,
		bytes: 1,
		kb: 1000,
		kib: 1024,
		mb: 1000000,
		mib: 1048576,
		gb: 1000000000,
		gib: 1073741824,
		tb: 1000000000000,
		tib: 1099511627776,
		pb: 1000000000000000,
		pib: 1125899906842624
	};

	if (matches) {
		var multiplier = multipliers[matches[2].toLowerCase()];
		return parseFloat(matches[1]) * multiplier;
	} else {
		return -1;
	}
	;
};

$(document).ready(function () {
	var datatables_lang = window['i18n']['common']['datatables-lang'];

	$('.datatable.basic').DataTable({
		"pageLength": 5,
		"language": {
			"url": "/js/vendor/datatables/" + datatables_lang + ".lang"
		},
		stateSave: true, //https://datatables.net/examples/basic_init/state_save.html
		"stateDuration": 0
	});

	/*
	 * datatable with Column Search & fileSize sortable columns
	 * @note table needs <tfoot> for select inputs
	 * @url https://datatables.net/plug-ins/sorting/
	 * @url https://datatables.net/examples/api/multi_filter_select.html
	 * @url https://datatables.net/plug-ins/sorting/file-size
	 */
	$('.datatable.columnSearch').DataTable({
		dom: 'fBltip',
		initComplete: function () {
			var search_lbl = (this.DataTable().i18n('sSearchPlaceholder'));
			//input search on every column except .noSelect
			this.api().columns(':not(.noSelect)').every(function () {
				var column = this;
				var footer = column.footer();

				var search = $('<input type="text" placeholder="' + search_lbl + ' ' + $(footer).text() + '" />')
					.appendTo($(footer).empty())
					.on('keyup change', function () {
						if (column.search() !== this.value) {
							column
								.search(this.value)
								.draw();
						}
					})
			});
		},
		buttons: [
			{
				text: '<i class="fal fa-filter"></i> reset',
				action: function ( e, dt, node, config ) {
					this.state.clear();
					location.reload();
				},
				className: 'btn-noIcon btn-sm m-0',
				titleAttr: 'Filter reset'

			}
		],
		"columnDefs": [
			{targets: 'noSort', orderable: false},
			{"type": 'file-size', targets: 'fileSize'}
		],
		"language": {
			"url": "/js/vendor/datatables/" + datatables_lang + ".lang"
		},
		"order": [[0, 'desc']],
		stateSave: true, //https://datatables.net/examples/basic_init/state_save.html
		"stateDuration": 0
	});

	/*
	 * datatable with multiselect-checkboxes
	 * @url https://datatables.net/extensions/select/examples/initialisation/checkbox.html
	 */
	$('.datatable.select').DataTable({
		//var table = $('.datatable.select').DataTable({
		/*
		 * B: buttons
		 * f: filtering
		 * i: info
		 * l: length
		 * p: pagination
		 * r: processing display element
		 * t: the table
		 */
		dom: 'fBltip',
		buttons: [
			{extend: 'selectAll', className: 'btn-noIcon'},
			{extend: 'selectNone', className: 'btn-noIcon'}
		],
		select: true,
		columnDefs: [
			{
				orderable: false,
				className: 'select-checkbox',
				targets: 0
			},
			{
				"targets": 'noSort',
				"orderable": false
			}
		],
		select: {
			style: 'multi',
			selector: 'td:first-child'
		},
		order: [[1, 'asc']],
		"language": {
			"url": "/js/vendor/datatables/" + datatables_lang + ".lang"
		},
		stateSave: true, //https://datatables.net/examples/basic_init/state_save.html
		"stateDuration": 0
	});
});