<template>
	<div class="row gallery">
		<div v-for="(img) in items" class="col-2 d-flex align-content-between flex-wrap gallery-img">
				<a :href="orderPath(img)">
					<div class="thumb-wrapper">
						<img :src="thumbPath(img)" alt="original_name" class="img-fluid">
						<i class="fal fa-eye shadow"></i>
					</div>
				</a>
				<div class="img-name"><span>{{img.id}}: {{img.original_name}}</span></div>
		</div>
		<paginator :dataSet="dataSet" @changed="fetch"></paginator>
	</div>
</template>

<script>
	export default {
		created() {
			this.fetch()
		},
		props:[
			'order'
		],
		data() {
			return {
				dataSet: false,
				items: []
			}
		},
		computed: {
			//
		},
		methods: {
			fetch(page) {
				axios.get(this.url(page))
					.then(this.refresh)
			},
			refresh({data}) {
				this.dataSet = data;
				this.items = data.data;
			},
			url(page){
				//get page parameter from url or start with 1
				if(!page){
					let query = location.search.match(/page=(\d+)/)
					page = query ? query[1] : 1;
				}
				// return '/api/files/20?page=' + page;
				return `/api/files/${this.order}?page=${page}`
			},
			orderPath(img){
				return `/orders/${this.order}/file/${img.id}`;
			},
			thumbPath(img){
				return `/files/${img.id}/thumbnail`;
			}
		}
	}
</script>

<style lang="scss">

</style>