<template>
  <span class="upload-attachment">
    <input class="upload-attachment__input" :name="inputName + '[]'" :id="inputName" type="file" multiple v-on:change="onFileSelect">
    <label class="upload-attachment__label btn btn-sm btn-primary" :for="inputName">{{trans('buttons.append-files')}}</label>
    <span class="upload-attachment__info mr-2" v-if="files.length > 0">
      {{trans('buttons.attachments-selected')}} {{ filenames }}
    </span>
    <span class="upload-attachment__info mr-2" v-else>
      (.pdf, .doc, .xls, .jpg, ...)
    </span>
  </span>
</template>

<script>

	export default {
		props: ['inputName'],

		data() {
			return {
				files: []
			}
		},

		computed: {
			filenames: function () {
				var names = "";
				for (var i = 0; i < this.files.length; i++) {
					names += this.files[i].name;
					if (i < this.files.length - 1) {
						names += ", ";
					}
				}
				return names;
			}
		},

		methods: {
			onFileSelect: function (event) {
				this.files = event.target.files;
			}
		}
	}

</script>

<style lang="scss">
	.upload-attachment {
		display: inline-block;

		&__input {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}

		&__label {
			display: block;
			margin-bottom: 0.5rem;
		}
	}
</style>
