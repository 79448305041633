window.Laravel = { csrfToken: '{{ csrf_token() }}' };

window._ = require('lodash');

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
	window.$ = window.jQuery = require('jquery');

	//bootstrap 4 js
	require('bootstrap');
} catch (e) {}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

window.axios.defaults.headers.common['X-CSRF-TOKEN'] = window.Laravel.csrfToken;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo'

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });

/*
 * Font Awesome 5
 * @url https://fontawesome.com/how-to-use/with-the-api/setup/importing-icons
 */
import {library, dom }  from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons'
// import {
// 	faSquare as fasFaSquare
// } from '@fortawesome/pro-solid-svg-icons'

library.add(fal) //, fasFaSquare);

// Kicks off the process of finding <i> tags and replacing with <svg>
dom.watch()


/*
 * datatables
 */
import 'datatables.net';
import 'datatables.net-select';
import 'datatables.net-bs4';
import 'datatables.net-buttons';
import 'datatables.net-buttons-bs4';

/*
 * Import js files from git loaded npm packages
 * @TODO move to webpack.mix file (syntax?)
 */
import './../../node_modules/zurb-twentytwenty/js/jquery.event.move.js';
import './../../node_modules/zurb-twentytwenty/js/jquery.twentytwenty.js';

/*
 * Import js files from assets/js directory
 */
import './components/comments.js';
import './components/datatables.js';
import './components/twentytwenty.js';
import './components/modal.js';
import './components/scrolling.js';
