window.Vue = require('vue');
const _ = require('lodash');

Vue.prototype.trans = string => _.get(window.i18n, string);

import VueParticles from 'vue-particles'
Vue.use(VueParticles)

Vue.component('file-upload', require('./components/FileUpload.vue').default);
Vue.component('upload-attachment', require('./components/UploadAttachment.vue').default);
import copyAttachment from './components/CopyAttachment';
Vue.component('copy-attachment', copyAttachment);
Vue.component('file-button-next-button', require('./components/FileUploadNextButton.vue').default);
Vue.component('help-slideout', require('./components/helpSlideout.vue').default);
Vue.component('confirm-delete', require('./components/confirmDelete.vue').default);
import OrderSelect from './components/OrderSelect.vue';
Vue.component('order-select', OrderSelect);
import gallery from './components/Gallery';
Vue.component('gallery', gallery);
import paginator from './components/Paginator';
Vue.component('paginator', paginator);

const app = new Vue({
	el: '#app',
	data(){
		return {
			//
			menuOpen:false
		}
	}
	// components: [
	// 	'file-upload': file-upload,
	// 	'file-button-next-button',
	// 	'upload-attachment',
	// 	'help-slideout',
	// 	'confirm-delete'
	// ]
});