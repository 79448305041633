<template>
	<ul class="pagination" v-if="shouldPaginate">
		<li class="page-item">
			<a v-show="prevUrl" class="page-link" href="#" aria-label="Previous" rel="prev" @click.prevent="page--">
				<span aria-hidden="true"><i class="fal fa-angle-left"></i></span>
			</a>
		</li>
		<li class="page-item">
			<a v-show="nextUrl" class="page-link" href="#" aria-label="Next" rel="next" @click.prevent="page++">
				<span aria-hidden="true"><i class="fal fa-angle-right"></i></span>
			</a>
		</li>
	</ul>
</template>

<script>
	/**
	 * Paginator to cycle through paginated dataSets
	 * @todo page-item iterator 1 to last_page
	 */
	export default {
		props:
			['dataSet'],
		data(){
			return{
				page:1,
				prevUrl: false,
				nextUrl: false
			}
		},
		watch:{
			dataSet(){
				this.page = this.dataSet.current_page
				this.prevUrl = this.dataSet.prev_page_url
				this.nextUrl = this.dataSet.next_page_url
			},

			page(){
				this.broadcast();//.updateUrl();
			}
		},
		computed:{
			shouldPaginate(){
				return !! this.prevUrl || !! this.nextUrl
			}
		},
		methods: {
			broadcast(){
				return this.$emit('changed', this.page)
			},
			updateUrl(){
				history.pushState(null, null, '?page=' + this.page)
			}
		}
	}
</script>