var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "upload-attachment" }, [
    _c("input", {
      staticClass: "upload-attachment__input",
      attrs: {
        name: _vm.inputName + "[]",
        id: _vm.inputName,
        type: "file",
        multiple: ""
      },
      on: { change: _vm.onFileSelect }
    }),
    _vm._v(" "),
    _c(
      "label",
      {
        staticClass: "upload-attachment__label btn btn-sm btn-primary",
        attrs: { for: _vm.inputName }
      },
      [_vm._v(_vm._s(_vm.trans("buttons.append-files")))]
    ),
    _vm._v(" "),
    _vm.files.length > 0
      ? _c("span", { staticClass: "upload-attachment__info mr-2" }, [
          _vm._v(
            "\n    " +
              _vm._s(_vm.trans("buttons.attachments-selected")) +
              " " +
              _vm._s(_vm.filenames) +
              "\n  "
          )
        ])
      : _c("span", { staticClass: "upload-attachment__info mr-2" }, [
          _vm._v("\n    (.pdf, .doc, .xls, .jpg, ...)\n  ")
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }