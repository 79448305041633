var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row gallery" },
    [
      _vm._l(_vm.items, function(img) {
        return _c(
          "div",
          {
            staticClass:
              "col-2 d-flex align-content-between flex-wrap gallery-img"
          },
          [
            _c("a", { attrs: { href: _vm.orderPath(img) } }, [
              _c("div", { staticClass: "thumb-wrapper" }, [
                _c("img", {
                  staticClass: "img-fluid",
                  attrs: { src: _vm.thumbPath(img), alt: "original_name" }
                }),
                _vm._v(" "),
                _c("i", { staticClass: "fal fa-eye shadow" })
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "img-name" }, [
              _c("span", [
                _vm._v(_vm._s(img.id) + ": " + _vm._s(img.original_name))
              ])
            ])
          ]
        )
      }),
      _vm._v(" "),
      _c("paginator", {
        attrs: { dataSet: _vm.dataSet },
        on: { changed: _vm.fetch }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }