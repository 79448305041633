$(function(){
	/*
	 * Modal load via ajax
	 * Loads modal content via ajax from data-load-url specified on button/anchor that opens the modal
	 * @TODO error handling
	 */
	$('.modal').on('show.bs.modal', function(e) {
			//modal data url of button
		var loadurl = '/modal/' + $(e.relatedTarget).data('load-url');
		//console.log(loadurl);
		$(this).find('.modal-content-ajax').load(loadurl);
	});
	
	/*
	 * Update Modal content
	 * event delegation for dynamically created modal
	 * @TODO error handling
	 */
	$(document).on('click', '.modalUpdate', function(e) {
			//modal data url of button
		var loadurl = '/modal/' + $(this).data('load-url');
		var modalContent = $('.modal').find('.modal-content-ajax');
		modalContent.load(loadurl);
	});
	
	/*
	 * Popover content load via ajax
	 */
	$('*[data-url-po]').focusin(function() {
	    var btnPo=$(this);
	    var loadurl = APP_URL + '/popover/' + btnPo.data('url-po');
//	    console.log(btnPo);
//	    console.log(loadurl);
	    $.get(loadurl,function(d) {
	        btnPo.popover({
	        	container: '.box',
	        	content: d,
	        	html: true,
	        	placement: 'bottom',
	        	trigger: 'focus'
	        }).popover('show');
	    });
	});
});