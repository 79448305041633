var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", [
    _c(
      "a",
      {
        staticClass: "copy-attachment",
        attrs: { href: "" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.linkAttachment($event)
          }
        }
      },
      [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.linked,
                expression: "linked"
              }
            ]
          },
          [
            _c("i", { staticClass: "fal fa-file-import text-info" }),
            _vm._v(" "),
            _c("i", { staticClass: "fal fa-times text-info" })
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.linked,
                expression: "!linked"
              }
            ]
          },
          [
            _vm.type ? _c("i", { staticClass: "fal fa-angle-left" }) : _vm._e(),
            _vm._v(" "),
            _c("i", { staticClass: "fal fa-file-import" }),
            _vm._v(" "),
            !_vm.type
              ? _c("i", { staticClass: "fal fa-angle-right" })
              : _vm._e()
          ]
        )
      ]
    ),
    _vm._v(" "),
    _vm.linked
      ? _c("input", {
          attrs: {
            type: "hidden",
            name: _vm.commentType + "copiedAttachments[]"
          },
          domProps: { value: _vm.attachmentId }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }