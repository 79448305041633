/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('./vue-app');

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */


$(function () {

	$('#sidenavCollapse').on('click', function () {
		$('#sidenav').toggleClass('active');
		$('body').toggleClass('active');
	});

	/*
	 * Dismissible alerts fade out
	 */
	window.setTimeout(function () {
		$(".alert.alert-fadeOut").fadeTo(500, 0).slideUp(500, function () {
			$(this).remove();
		});
	}, 4000);

	/*
	 * Enable Popovers
	 */
	$('[data-toggle="popover"]').popover({
		trigger: 'click',
		html: true
	});

	/*
	 * Switch angle-up/down icon on accordion collapse
	 */
	$('a[data-toggle="collapse"]').on('click', function (e) {
		var _this = $(this);
		var accordion = $(_this.data("parent"));
		//fa-icon of clicked entry
		var clickedIcon = _this.find("svg");
		//find open accordion entries, reset fa-icon to angle-down
		var openEntries = accordion.find("svg[data-icon='angle-up']");
		openEntries.attr("data-icon", 'angle-down')
		//change angle on clicked accordion entry
		if (_this.hasClass("collapsed")) {
			clickedIcon.attr('data-icon', 'angle-up')
		}
	});

	//disable chrome datepicker
	// $('input[type="date"]').attr('type', 'text');

	//enable clean FTP btn on 2nd click
	$('#btn_cleanFtp').one('click', function () {
		$('#btn_cleanFtp_confirm').removeClass('invisible');
	})

	/*
	 * Show/Hide approve button
	 */
	var oldVal = "";
	$("textarea").on("change keyup paste", function () {
		var currentVal = $(this).val();
		if (currentVal == oldVal) {
			return; //check to prevent multiple simultaneous triggers
		}

		oldVal = currentVal;
		//action to be performed on textarea changed
		//console.log("changed!");
		$('.approve').show();
	});


	$('.js-download-selected').on('click', function () {
		var files = [];
		var $btn = $(this);
		$('.js-files').find('tr.selected').each(function () {
			var fileId = $(this).attr('data-file-id');
			files.push(fileId);
		});
		if (files.length === 0) {
			return false;
		}
		var href = $btn.attr('data-download-url') + files.join(',');
		$(this).attr('href', href);
		return true;
	});

	$('.js-input-attachment').change(function () {
		var $input = $(this);
		var files = $input.get(0).files;
		//console.log(files);
		$input.closest('form').find('.js-attachments-info').html(files.length + " ausgewählt");
	});
});
