var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "button",
      {
        staticClass: "btn",
        class: _vm.btnClass,
        on: {
          click: function($event) {
            _vm.show = !_vm.show
          }
        }
      },
      [_vm._t("default")],
      2
    ),
    _vm._v(" "),
    _vm.show
      ? _c(
          "a",
          {
            staticClass: "btn btn-primary btn-sm",
            attrs: { href: _vm.btnUrl }
          },
          [
            _c("i", { staticClass: "text-danger fal fa-exclamation-triangle" }),
            _vm._v(" " + _vm._s(_vm.trans("buttons.confirm")) + "\n\t")
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }