<template>
	<v-select v-model="selected" :options="options" @input="loadOrder" class="bg-medium">
	</v-select>
</template>

<script>
	/**
	 * vue-select to display recent orders
	 * @param options: array with json-data {value: orderId, label: 'orderId [customer] orderTitle'},{...}
	 */
	import vSelect from 'vue-select'

	export default {
		
		data() {
			return {
				selected: null,
			}
		},
		props:{
			'options' : {
				type: Array
			},
		},
		methods: {
			loadOrder() {
				if(this.selected){
					location.href = this.selected.value
				}
			}
		},
		components:{
			vSelect
		}
	}
	
</script>

<style lang="scss">
</style>