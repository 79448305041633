var imagesLoaded = require('imagesloaded');

$(document).ready(function() {
  var $before = $('.js-before'),
    $after = $('.js-after'),
    $versionSize = $('.js-version-size'),
    $versionCreationDate = $('.js-version-creation-date');

  	imagesLoaded(".twentytwenty-container", function() {
  		$(".twentytwenty-container").twentytwenty({
  			before_label: $before.attr('alt'), // Set a custom before label
  			after_label: $after.attr('alt'), // Set a custom after label
  		});
  	});

  $('.js-versions').find('a').on('click', function(e) {
    e.preventDefault();
    var $this = $(this),
      before = $this.attr('data-before'),
      src = $this.attr('data-src'),
      size = $this.attr('data-size'),
      creationDate = $this.attr('data-creation-date'),
      alt = $this.attr('data-alt');

    if(before) {
      $before.attr('src', src);
      $('.twentytwenty-before-label').attr('data-content', alt)
    } else {
      $after.attr('src', src);
      $versionCreationDate.text(creationDate);
      $versionSize.text(size);
      $('.twentytwenty-after-label').attr('data-content', alt)
    }
  })
});
