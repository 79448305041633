<template>
    <div>
    	<div class="row">
	        <div class="col-4">
	        	<button type="button" class="btn btn-primary mb-1" id="file-select-btn" :title="trans('buttons.fileselect')">
	        		<span v-if="!showUpload">1. {{ trans('buttons.fileselect') }}</span>
					<span v-else>1. {{ trans('buttons.fileselect-more') }}</span>
	        	</button>
	        	<p>&nbsp;(.psd, .png, .jpg, ...)</p>
	        </div>
        	<div class="col-4 text-center">
        		<button type="button" class="btn btn-primary mb-3" id="file-upload-start-btn" :title="trans('labels.upload-start')"
        			v-on:click="uploadFiles" v-show="showUpload">
        			2. <i class="fal fa-upload"></i>&nbsp;{{ trans('buttons.upload-start') }}
        		</button>
        	</div>
        	<div class="col-4 text-right">
        		<a href="#next" class="btn btn-primary btn-noIcon scroll mb-3" v-show="showNext">
        			<i class="fal fa-angle-down"></i>&nbsp;{{ trans('buttons.next') }}&nbsp;<i class="fal fa-angle-down"></i>
        		</a>
        	</div>
	    </div>
        <form action="/files/upload" class="border-dashed text-center dropzone p-5" id="dropzone">
        
        	<h2 class="dz-message" data-dz-message v-show="!showUpload">{{ trans('headings.transfer-uploadhint') }}</h2>
	        <div class="d-flex justify-content-end">
	        	<button type="button" class="btn btn-primary btn-noIcon mb-3" :title="trans('labels.upload-removeAll')" 
	        		v-on:click="removeAll" v-show="showUpload">
	        		<i class="fal fa-trash-alt"></i>&nbsp;{{ trans('buttons.upload-removeAll') }}
	        	</button>
	        </div>
      
			<div id="previews" class="dz-previews">
			  <div class="row align-items-center my-3" id="template">
			    <div class="col-md-2 hidden-sm-down">
			        <span class="preview"><img data-dz-thumbnail /></span>
			    </div>
			    <div class="col-sm-10 col-md-8">
			        <span class="name" data-dz-name></span>&nbsp;<span class="size" data-dz-size></span>
			        <span class="upload-success"><i class="fal fa-lg fa-check"></i></span>
			        <strong class="error text-danger" data-dz-errormessage></strong>
			        <div class="progress progress-striped active" role="progressbar"
			            aria-valuemin="0" aria-valuemax="100" aria-valuenow="0">
			            <div class="progress-bar progress-bar-success" style="width: 0%;" data-dz-uploadprogress>
			            </div>
			        </div>
			    </div>
			    <div class="col-sm-2">
			        <button data-dz-remove class="btn btn-sm btn-noIcon btn-primary delete" :title="trans('labels.delete-file')">
			            <i class="fal fa-trash-alt"></i>
			        </button>
			    </div>
			  </div>
			</div>
      
        </form>
    </div>
</template>

<script>
	let Dropzone = require('dropzone');
	import EventBus from '../helper/event-bus.js';
	Dropzone.autoDiscover = false;

	export default {
		props: ['csrfToken', 'orderId'],

		data() {
			return {
				files: [],
				showUpload: false,
				showNext: false
			}
		},

		mounted() {
			var vm = this;
			// Get the template HTML and remove it from the document
			 var previewNode = document.querySelector("#template");
			 previewNode.id = "";
			 var previewTemplate = previewNode.parentNode.innerHTML;
			 previewNode.parentNode.removeChild(previewNode);

			this.dropzone = new Dropzone('#dropzone', {
				acceptedFiles: 'image/*,.psd,.ai,.eps,.pdf,.cr2,.dng,.nef,.raw,.zip,.rar,.raf',
				autoProcessQueue: false,
				clickable: '#file-select-btn',
				previewTemplate: previewTemplate,
				previewsContainer: '#previews',
				sending: function(file, xhr, formData) {
					formData.append('_token', vm.csrfToken);
					formData.append('order_id', vm.orderId);
				},
				timeout: 600000, // 10 mins
				createImageThumbnails: false,
				//thumbnailWidth: 40,
				//thumbnailHeight: 40,
				parallelUploads: 4,
				maxFilesize: 1024
			});

			this.dropzone.on('addedfile', function (file) {
				// remove duplicate images 
				if (this.files.length) {
					var _i, _len;
					for (_i = 0, _len = this.files.length; _i < _len - 1; _i++) // -1 to exclude current file
					{
						if(this.files[_i].name === file.name && this.files[_i].size === file.size && this.files[_i].lastModifiedDate.toString() === file.lastModifiedDate.toString())
						{
							this.removeFile(file);
						}
					}
				}
				EventBus.$emit('ADDED_FILE');
				vm.showUpload = true;
			});
			
			/*
			 * Upload all files after first queue
			 * @note https://github.com/enyo/dropzone/issues/253#issuecomment-22184190
			 */
			this.dropzone.on('processing', function (file, response) {
				this.options.autoProcessQueue = true;
			});
			
			this.dropzone.on('success', function (file, response) {
				file['id'] = response.id;
				vm.files.push(file);
				EventBus.$emit('FILES_CHANGED', vm.files);
				vm.showNext = true;
			});

			this.dropzone.on('queuecomplete', function() {
        if(vm.files.length > 0) {
				  EventBus.$emit('FILES_UPLOADED');
        }
			});

			this.dropzone.on('removedfile', function (file) {

				//delete file from server if already uploaded (not in queue or canceled)
				if (file.status == "success") {
					var deleteFile = _.find(vm.files, function(f) {
						return f.name === file.name;
					});
					axios.delete('/files/' + deleteFile.id, {
						headers: {'X-CSRF-TOKEN': vm.csrfToken}
					})
					.then(function(response) {
						_.remove(vm.files, function(f) {
							return f.id === deleteFile.id;
						});
						EventBus.$emit('FILES_CHANGED', vm.files);
					})
					.catch(function(error) {
						console.log(error);
					});
				}
				
					//disable Next button on empty filelist
				if (!this.files.length) {
					EventBus.$emit('FILES_EMPTY');
					vm.showUpload = false;
					vm.showNext = false;
				}
			});
			
		}, // mounted()
		
		methods: {
			removeAll() {
					//doesn´t cancel active uploads
				this.dropzone.removeAllFiles();
					//cancels active uploads
				//this.dropzone.removeAllFiles(true);
				//console.log('removeAll');
				this.showUpload = false;
				this.showNext = false;
			},
			
			uploadFiles() {
				this.dropzone.processQueue();
				// console.log("uploadFiles");
			}
		}
		
	} // export default
</script>

<style lang="scss">
	
</style>

