$(document).ready(function () {

	var $body = $('html, body');	//cache html-body selector

	/*
	 * Scrolling to Anchor
	 * All links with class="scroll" smooth scroll to id-target
	 */
	$('a.scroll').on('click', function (e) {
		e.preventDefault();

		var target = this.hash;
		var $target = $(target);

		$body.stop().animate({
			'scrollTop': $target.offset().top - 50
		}, 900, 'swing', function () {
			window.location.hash = target;
		});
	});

	/*
	 * Scroll to top/bottom based on current window position
	 */
	$(window).scroll(function () {
		var height = $body.height();
		//scrollbar position + 10 px offset
		var top = $(window).scrollTop() + 10;
		var btn = $('#back-to-top');
		var icon = btn.children('svg');

		// scroll to top if at bottom, scroll to bottom if at top
		if (top > height / 2) {
			btn.data("position", '#top');
			icon.attr("data-icon", 'angle-up')
		} else {
			btn.data("position", '#footer');
			icon.attr("data-icon", 'angle-down');
		}

		// show scroll btn when scrolling down past 60px
		if ($(this).scrollTop() > 60) {
			$('#back-to-top').fadeIn();
		} else {
			$('#back-to-top').fadeOut();
		}
	});

	/*
	 * Scroll to top/bottom
	 */
	$('#back-to-top').click(function () {
		var pos = $(this).data("position");
		$body.animate({
			scrollTop: $(pos).offset().top
		}, 900);
		return false;
	});

	/*
		Scroll to anchor id on select2 select
	 */
	$('.select2.scrollToSelection').on('select2:select', function (e) {
		var target = '#' + e.params.data.id;
		var $target = $(target);
		//check if id is found, else scroll to top
		if($target.length == 0){
			$target = $('#top');
		}

		$body.stop().animate({
			'scrollTop': $target.offset().top - 80
		}, 900, 'swing', function () {
			window.location.hash = target;
		});

		// $('#' + data.id).scroll();
		// $("#file_1").scrollTop();
		// $($body).animate({scrollTop: $('#file_1').offset().top}, 'slow');
	})

});