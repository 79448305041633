var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.shouldPaginate
    ? _c("ul", { staticClass: "pagination" }, [
        _c("li", { staticClass: "page-item" }, [
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.prevUrl,
                  expression: "prevUrl"
                }
              ],
              staticClass: "page-link",
              attrs: { href: "#", "aria-label": "Previous", rel: "prev" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.page--
                }
              }
            },
            [_vm._m(0)]
          )
        ]),
        _vm._v(" "),
        _c("li", { staticClass: "page-item" }, [
          _c(
            "a",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.nextUrl,
                  expression: "nextUrl"
                }
              ],
              staticClass: "page-link",
              attrs: { href: "#", "aria-label": "Next", rel: "next" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  _vm.page++
                }
              }
            },
            [_vm._m(1)]
          )
        ])
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", { staticClass: "fal fa-angle-left" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { attrs: { "aria-hidden": "true" } }, [
      _c("i", { staticClass: "fal fa-angle-right" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }