<script>
		
	export default {
	
		// template: '#confirm-delete',
		props: ['btnUrl', 'btnClass'],
		data() {
			return {
				show: false,
			}
		}
	}
</script>

<template>
	<div>
		<button class="btn" :class="btnClass" v-on:click="show = !show">
			<slot></slot>
		</button>
		<a :href="btnUrl" class="btn btn-primary btn-sm" v-if="show">
			<i class="text-danger fal fa-exclamation-triangle"></i> {{trans('buttons.confirm')}}
		</a>
	</div>

</template>
<style lang="scss">
	
</style>