<script>
		
	export default {
	
		template: '#help-slideout',
		
		data() {
			return {
				isOpen: false,
			}
		},
		methods: {
			open() {
				this.isOpen = true;
			},
			close() {
				this.isOpen = false;
			},
			toggle() {
				if (this.isOpen) {
					this.close();
				} else {
					this.open();
				}
			}
		}
	}
	
</script>

<style lang="scss">
	
</style>