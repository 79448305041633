var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-4" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-primary mb-1",
            attrs: {
              type: "button",
              id: "file-select-btn",
              title: _vm.trans("buttons.fileselect")
            }
          },
          [
            !_vm.showUpload
              ? _c("span", [
                  _vm._v("1. " + _vm._s(_vm.trans("buttons.fileselect")))
                ])
              : _c("span", [
                  _vm._v("1. " + _vm._s(_vm.trans("buttons.fileselect-more")))
                ])
          ]
        ),
        _vm._v(" "),
        _c("p", [_vm._v(" (.psd, .png, .jpg, ...)")])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4 text-center" }, [
        _c(
          "button",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showUpload,
                expression: "showUpload"
              }
            ],
            staticClass: "btn btn-primary mb-3",
            attrs: {
              type: "button",
              id: "file-upload-start-btn",
              title: _vm.trans("labels.upload-start")
            },
            on: { click: _vm.uploadFiles }
          },
          [
            _vm._v("\n        \t\t\t2. "),
            _c("i", { staticClass: "fal fa-upload" }),
            _vm._v(
              " " + _vm._s(_vm.trans("buttons.upload-start")) + "\n        \t\t"
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-4 text-right" }, [
        _c(
          "a",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showNext,
                expression: "showNext"
              }
            ],
            staticClass: "btn btn-primary btn-noIcon scroll mb-3",
            attrs: { href: "#next" }
          },
          [
            _c("i", { staticClass: "fal fa-angle-down" }),
            _vm._v(" " + _vm._s(_vm.trans("buttons.next")) + " "),
            _c("i", { staticClass: "fal fa-angle-down" })
          ]
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        staticClass: "border-dashed text-center dropzone p-5",
        attrs: { action: "/files/upload", id: "dropzone" }
      },
      [
        _c(
          "h2",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.showUpload,
                expression: "!showUpload"
              }
            ],
            staticClass: "dz-message",
            attrs: { "data-dz-message": "" }
          },
          [_vm._v(_vm._s(_vm.trans("headings.transfer-uploadhint")))]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-end" }, [
          _c(
            "button",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showUpload,
                  expression: "showUpload"
                }
              ],
              staticClass: "btn btn-primary btn-noIcon mb-3",
              attrs: {
                type: "button",
                title: _vm.trans("labels.upload-removeAll")
              },
              on: { click: _vm.removeAll }
            },
            [
              _c("i", { staticClass: "fal fa-trash-alt" }),
              _vm._v(
                " " +
                  _vm._s(_vm.trans("buttons.upload-removeAll")) +
                  "\n\t        \t"
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "dz-previews", attrs: { id: "previews" } }, [
          _c(
            "div",
            {
              staticClass: "row align-items-center my-3",
              attrs: { id: "template" }
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "col-sm-2" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-sm btn-noIcon btn-primary delete",
                    attrs: {
                      "data-dz-remove": "",
                      title: _vm.trans("labels.delete-file")
                    }
                  },
                  [_c("i", { staticClass: "fal fa-trash-alt" })]
                )
              ])
            ]
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-2 hidden-sm-down" }, [
      _c("span", { staticClass: "preview" }, [
        _c("img", { attrs: { "data-dz-thumbnail": "" } })
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-sm-10 col-md-8" }, [
      _c("span", { staticClass: "name", attrs: { "data-dz-name": "" } }),
      _vm._v(" "),
      _c("span", { staticClass: "size", attrs: { "data-dz-size": "" } }),
      _vm._v(" "),
      _c("span", { staticClass: "upload-success" }, [
        _c("i", { staticClass: "fal fa-lg fa-check" })
      ]),
      _vm._v(" "),
      _c("strong", {
        staticClass: "error text-danger",
        attrs: { "data-dz-errormessage": "" }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "progress progress-striped active",
          attrs: {
            role: "progressbar",
            "aria-valuemin": "0",
            "aria-valuemax": "100",
            "aria-valuenow": "0"
          }
        },
        [
          _c("div", {
            staticClass: "progress-bar progress-bar-success",
            staticStyle: { width: "0%" },
            attrs: { "data-dz-uploadprogress": "" }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }