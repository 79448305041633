<template>
	<span>
		<a href="" class="copy-attachment" @click.prevent="linkAttachment">
			<span v-show="linked">
				<i class="fal fa-file-import text-info"></i>
				<i class="fal fa-times text-info"></i>
			</span>
			<span v-show="!linked">
				<i v-if="type" class="fal fa-angle-left"></i>
				<i class="fal fa-file-import"></i>
				<i v-if="!type" class="fal fa-angle-right"></i>
			</span>
		</a>
		<input v-if="linked" type="hidden" :name="commentType + 'copiedAttachments[]'" :value="attachmentId" />
	</span>
</template>

<script>
	// import jQuery from 'jquery'
	// let $ = jQuery

	export default {
		props: [
			'attachmentId',
			'type'
		],

		data() {
			return {
				linked: false,
				summary: ''
			}
		},
		computed: {
			commentType: function(){
				if (this.type == 'team') {
					return 'team_'
				} else {
					return ''
				}
			},
		},
		mounted(){
			// let that = this;
			// $(function () {
			// 	this.summary = $('#copied_' + that.commentType + 'attachments')
			// 	this.summary.append('test' + that.commentType)
			// })
		},
		methods: {
			linkAttachment(){
				this.linked = !this.linked
 			}
		}
	}
</script>

<style lang="scss">
</style>
