<template>
  <button id="next" type="button" v-on:click="next" class="btn btn-primary" v-show="!disabled">3. {{ trans('buttons.next') }}</button>
</template>

<script>
    import EventBus from '../helper/event-bus.js';

    export default {
      props: ['link'],

      data() {
        return {
          disabled: true 
        }
      },

      methods: {
        next: function() {
          location.href=this.link;
        }
      },

      mounted() {
        var vm = this;
        EventBus.$on('ADDED_FILE', function () {
          vm.disabled = true;
        });
        EventBus.$on('FILES_UPLOADED', function () {
          vm.disabled = false;
        });
        EventBus.$on('FILES_EMPTY', function () {
          vm.disabled = true;
        });
      }
    }
</script>
